:root {
  --logo-url: url(../images/logo-light.png?v=1);
  --logo-m-url: url(../images/logo-light.png?v=1);
  --bg-color: #f4f5f7;
  --bg-color-transparent: rgba(255, 255, 253, 0.8);

  --theme-color: #f4f5f7;

  --bg-hover: rgba(0, 0, 0, 0.05);

  --surface-bg-color: #fffffc;

  --divider-color: #d9dce4;

  --btn-bg-color: #3d50fa;
  --btn-bg-hover-color: #2a3ffa;
  --disabled-bg-color: #d9dce1;

  --text-primary-color: #fffffc;
  --text-secondary-color: #030303;
  --text-tertiary-color: #a5acbe;

  --alert-color: #ffbe08;
  --error-color: #ff0c0c;
  --success-color: #57d9b7;

  --accent-color: #3d50fa;
  --map-filter: none;
  --image-theme-filter: none;

  --pastel-shadow-color: 0px 0px 7px 1px rgba(217, 220, 228, 0.6);
}

body {
  background-color: var(--bg-color);
  color: var(--text-tertiary-color);
}

.sidenav-fixed {
  width: 220px;
}

footer {
  /* background-color: var(--bg-color) !important; */
}

tr {
  border-bottom: 1px solid var(--divider-color);
}

li.active {
  background-color: var(--accent-color) !important;
  color: var(--text-primary-color) !important;
}

textarea.materialize-textarea {
  line-height: 24px;
}

.file-field .btn,
.file-field .btn-large,
.file-field .btn-small {
  float: none;
  height: 36px;
  line-height: 36px;
}

.pagination-page {
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

.pagination-page-prev {
  padding-left: 0px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

.pagination-page-next {
  padding-left: 8px;
  padding-right: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .unitable {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

.th-amount-min {
  width: 100px;
}

.footer-copyright {
  color: var(--text-tertiary-color) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.divider {
  background-color: var(--divider-color);
}

.hljs {
  background-color: var(--surface-bg-color);
  border-radius: 15px;
  padding: 15px !important;
}

nav ul a:hover {
  background-color: var(--bg-hover);
}

.sidenav li > a:hover {
  /* background-color: var(--bg-hover) !important; */
  border-radius: 10px;
}

.material-icons {
  color: var(--text-tertiary-color) !important;
}

.material-icons-outlined {
  color: var(--text-tertiary-color) !important;
}

.material-symbols-outlined {
  /* color: var(--text-tertiary-color) !important; */
}

.sidenav {
  background-color: var(--bg-color);
}

.sidenav li .material-icons {
  color: var(--accent-color) !important;
}

.sidenav li .material-icons-outlined {
  color: var(--accent-color) !important;
}

.sidenav li .material-symbols-outlined {
  color: var(--accent-color);
}

.sidenav li a {
  color: var(--text-secondary-color);
}

.user-view {
  color: var(--text-secondary-color);
}

.spinner-layer {
  border-color: var(--accent-color);
}

.m-change-theme-btn {
  margin-top: 3px;
  margin-right: 15px;
  cursor: pointer;
}

.modal-content {
  background-color: var(--bg-color);
}

.modal-footer {
  background-color: var(--bg-color) !important;
}

.modal.modal-fixed-footer .modal-footer {
  border-top: 1px solid var(--divider-color);
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_100_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_100.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_300_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_300.otf") format("opentype");
}

@font-face {
  font-family: "Museo_Sans_300";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_300_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_300.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_500_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_500.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_700_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_700.otf") format("opentype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Museo Sans"),
    url("../fonts/MuseoSans_900_normal.woff") format("woff"),
    url("../fonts/MuseoSansCyrl_900.otf") format("opentype");
}

.dropdown-content,
.select-dropdown {
  font-family: "Museo Sans" !important;
  font-weight: 300;
}

input {
  font-family: "Museo Sans" !important;
  font-weight: 300;
  color: var(--text-secondary-color);
}

button {
  font-family: "Museo Sans" !important;
  font-weight: 300;
}

.title {
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
}

html {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

h1 {
  font-weight: 900;
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--text-secondary-color);
}

h2 {
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;
  margin-top: 0px;
  color: var(--text-secondary-color);
}

h3 {
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: var(--text-secondary-color);
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-secondary-color);
}

h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-secondary-color);
}

h6 {
  font-weight: 300;
  font-size: 12px;
  color: var(--text-secondary-color);
}

.page-title {
  margin-top: 22px;
}

.card {
  background-color: var(--surface-bg-color);
  box-shadow: var(--light-shadow);
  border-radius: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.s-card-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.xxxl500 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  color: var(--text-secondary-color);
}

.xxl500 {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--text-secondary-color);
}

.xxl700 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  color: var(--text-secondary-color);
}

.xl700 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--text-secondary-color);
}

.xl500 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-secondary-color);
}

.l500c {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--text-secondary-color);
}

.l700 {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.l500 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--text-secondary-color);
}

.l300 {
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
}

.m700 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.m500 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--text-secondary-color);
}

.m300 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

.s500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-secondary-color);
}

.s300 {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.xs300 {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.xxs300 {
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
}

.main-card-header {
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
}

.main-flow-date {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
}

.main-card-amount {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

.progress {
  margin-top: 5px;
  margin-bottom: 12px;
}

.navigation-bar {
  margin-bottom: 0px;
}

.nm {
  margin-bottom: 0px;
  margin-top: 0px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.green-button {
  background-color: #68c305;
}

.green-button:hover {
  background-color: #68c305;
}

.red-button {
  background-color: #fb8202;
  color: #ffffff !important;
}

input[type="submit"] {
  color: #ffffff;
}

.blue-button {
  background-color: #2c75ff;
  color: white;
  margin-left: 10px;
  text-transform: none;
}

.blue-button:hover {
  background-color: #2c75ff;
}

.white-btn {
  text-transform: none;
  background-color: var(--surface-bg-color);
  color: var(--accent-color);
  cursor: pointer;
  box-shadow: none;
  border-radius: 8px;
}

.white-btn:focus {
  background-color: var(--surface-bg-color);
}

.white-btn:hover {
  background-color: var(--surface-bg-color);
  box-shadow: none;
}

.white-btn .material-icons-outlined {
  color:  var(--accent-color) !important;
}

.white-btn .material-icons {
  color:  var(--accent-color) !important;
}

.white-button:hover {
  background-color: #fff;
}

.red-button:focus {
  background-color: #fb8202;
}

.grey-button {
  color: black;
  background-color: #f5f5f5;
}

.grey-button:focus {
  background-color: #f5f5f5;
}

.red-button:hover {
  background-color: #fb8202;
}

.iq-btn:focus {
  background-color: var(--btn-bg-color);
}

.pastel-btn:focus {
  background-color: var(--bg-color) !important;
}

.grey-button:hover {
  background-color: #fb8202;
  color: white;
}

.sypher-blue {
  color: #2c75ff;
}

.iq-color {
  color: #311b92;
}

.iq-logo {
  width: 120px;
  height: 80px; 
  background-image: var(--logo-url);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* -webkit-box-shadow: var(--pastel-shadow-color);
  -moz-box-shadow: var(--pastel-shadow-color);
  box-shadow: var(--pastel-shadow-color); */
  /* border-radius: 20px; */
}

.iq-logo-m {
  width: 120px;
  height: 30px;
  margin-top: 13px;
  background-image: var(--logo-m-url);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.pastel-shadow {
  -webkit-box-shadow: var(--pastel-shadow-color);
  -moz-box-shadow: var(--pastel-shadow-color);
  box-shadow: var(--pastel-shadow-color);
}

.alert {
  color: var(--alert-color) !important;
}

.error {
  color: var(--error-color) !important;
}

.error-bg {
  background-color: var(--error-color) !important;
}

.alert-message {
  color: var(--text-secondary-color);
  background-color: rgba(225, 255, 255, 0);
  border: 2px var(--alert-color) solid;
  /* margin-top: 25px; */
}

.error-message {
  color: var(--error-color);
  background-color: rgba(225, 255, 255, 0);
  border: 2px var(--error-color) solid;
  border-radius: 15px;
  /* margin-top: 25px; */
}

.alert-message .card-content {
  padding: 17px;
}

.iq-btn {
  text-transform: none;
  background-color: var(--btn-bg-color);
  color: var(--text-primary-color);
  cursor: pointer;
  box-shadow: none;
  border-radius: 8px;
}

.iq-btn:hover {
  background-color: var(--btn-bg-hover-color);
  box-shadow: none;
}

.iq-btn .material-icons-outlined {
  color: var(--text-primary-color) !important;
}

.iq-btn .material-icons {
  color: var(--text-primary-color) !important;
}

.iq-btn.disabled {
  background-color: var(--disabled-bg-color) !important;
}

.iq-btn.disabled .material-icons-outlined {
  color: var(--text-tertiary-color) !important;
}

.iq-btn.disabled .material-icons {
  color: var(--text-tertiary-color) !important;
}

.iq-btn-delete {
  text-transform: none;
  background-color: var(--surface-bg-color);
  color: var(--error-color);
  cursor: pointer;
  font-weight: 500;
}

.iq-btn-delete .material-icons-outlined {
  color: var(--error-color) !important;
}

.iq-btn-delete .material-icons {
  color: var(--error-color) !important;
}

.iq-btn-delete:hover {
  background-color: var(--divider-color);
  color: var(--error-color);
}

.iq-btn-delete.disabled .material-icons-outlined {
  background-color: var(--surface-bg-color) !important;
  color: var(--text-tertiary-color) !important;
}

.iq-btn-delete.disabled .material-icons {
  background-color: var(--surface-bg-color) !important;
  color: var(--text-tertiary-color) !important;
}

.iq-btn-delete.disabled {
  color: var(--text-tertiary-color) !important;
}

.pastel-btn {
  background-color: var(--bg-color);
  color: var(--accent-color);
  border-radius: 8px;
}

.pastel-btn:hover {
  background-color: var(--bg-color);
  color: var(--accent-color);
  border-radius: 8px;
}

.pastel {
  background-color: var(--bg-color);
}

i.left {
  margin-right: 8px;
}

.iq-link {
  text-decoration: underline;
  color: var(--accent-color);
  cursor: pointer;
}

.iq-tertiary-link {
  text-decoration: underline;
  color: var(--text-tertiary-color);
  cursor: pointer;
}

a.disabled {
  pointer-events: none;
}

.accent-field {
  background-color: var(--btn-bg-color);
  color: var(--text-primary-color);
  border-radius: 100px;
  padding: 10px !important;
}

.accent-color {
  color: var(--accent-color) !important;
}

.secondary-color {
  color: var(--text-secondary-color) !important;
}

.primary-color {
  color: var(--text-primary-color) !important;
}

.tertiary-color {
  color: var(--text-tertiary-color) !important;
}

.success-color {
  color: var(--success-color) !important;
}

.error-color {
  color: var(--error-color) !important;
}

.alert-color {
  color: var(--alert-color) !important;
}

.iq-color-bg {
  background-color: #311b92 !important;
}

.page-footer {
  padding-top: 0px;
}

.first-element {
  margin-top: 25px;
}

.datepicker-date-display,
.timepicker-digital-display {
  background-color: var(--accent-color);
}

.timepicker-canvas line {
  stroke: var(--accent-color) !important;
}

.timepicker-canvas-bearing {
  fill: var(--accent-color) !important;
}

.timepicker-canvas-bg {
  fill: var(--accent-color) !important;
}

.timepicker-tick:hover {
  background: var(--accent-color) !important;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done,
.timepicker-close {
  color: var(--accent-color);
}

.datepicker-table td.is-today {
  color: var(--accent-color);
}

.datepicker-table td.is-selected {
  background-color: var(--accent-color);
  color: #fff;
}

.accent-color-bg {
  background-color: var(--accent-color);
}

.sypher-green {
  color: #069e2d;
}

#toast-container {
  top: auto !important;
  /* right: auto !important; */
  bottom: 15px;
  right: 15px;
  width: 500px;
  max-width: 100%;
  padding-left: 30px;
}

.toast {
  /* font-weight: 400; */
  line-height: 25px !important;
  border-radius: 0;
  /* height: 5em; */
  padding: 20px 25px;
  background-color: var(--accent-color);
  border-radius: 15px;
  -webkit-box-shadow: var(--pastel-shadow-color);
  -moz-box-shadow: var(--pastel-shadow-color);
  box-shadow: var(--pastel-shadow-color);
  display: block;
}

.section-header {
  margin-bottom: 20px;
  color: #fb8202;
}

.tabs .indicator {
  background-color: var(--accent-color);
}

.tabs .tab a {
  color: var(--accent-color);
  letter-spacing: 1px;
  text-transform: none;
  font-weight: 500;
}

.tabs .tab a.active {
  color: var(--accent-color);
}

.tabs .tab a:hover,
.tabs .tab a.active {
  background-color: transparent;
  color: var(--accent-color);
}

.tabs {
  border-radius: 15px;
  background-color: var(--bg-color);
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  border: 2px solid var(--accent-color);
  background-color: var(--accent-color);
}

/*
.checkbox-indigo[type="checkbox"] + label:before{
    border: 2px solid indigo;
    background: transparent;
    color: red;
  }
  .checkbox-indigo[type="checkbox"]:checked + label:before{
    border: 2px solid transparent;
    border-bottom: 2px solid indigo;
    border-right: 2px solid indigo;
    background: transparent;
    color: red;
  }
  .checkbox-indigo.filled-in[type="checkbox"] + label:after{
    border: 2px solid indigo;
    background: transparent;
    color: red;
  }
  .checkbox-indigo.filled-in[type="checkbox"]:checked + label:after{
    background: indigo;
    color: red;
  }
  .checkbox-indigo.filled-in[type="checkbox"]:checked + label:before{
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    color: red;
  }*/

ul.dropdown-content.select-dropdown li span {
  color: var(--accent-color);
}

[type="checkbox"]:checked + span:not(.lever):before {
  border-right: 2px solid var(--success-color);
  border-bottom: 2px solid var(--success-color);
}

[type="checkbox"]:checked:disabled + span:before {
  border-right: 2px solid var(--text-tertiary-color);
  border-bottom: 2px solid var(--text-tertiary-color);
}

[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: 2px solid var(--text-tertiary-color);
}

[type="checkbox"]:not(:checked):disabled + span:not(.lever):before {
  border: none;
  background-color: var(--text-tertiary-color);
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #fb8202;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #fb8202;
}

.fin-indicator {
  border-bottom: 1.5px dashed;
}

.fin-indicator:hover {
  cursor: pointer;
}

/* label focus color */
.input-field input:focus + label {
  color: var(--accent-color) !important;
}
.input-field label {
  color: var(--text-tertiary-color) !important;
}
/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid var(--accent-color) !important;
  box-shadow: 0 1px 0 0 var(--accent-color) !important;
}
.row .input-field input {
  border-bottom: 1px solid var(--text-tertiary-color) !important;
}

/* label focus color */
.input-field textarea:focus + label {
  color: var(--accent-color) !important;
}
/* label underline focus color */
.row .input-field textarea:focus {
  border-bottom: 1px solid var(--accent-color) !important;
  box-shadow: 0 1px 0 0 var(--accent-color) !important;
}

.row .input-field textarea {
  border-bottom: 1px solid var(--text-tertiary-color) !important;
}

textarea {
  color: var(--text-secondary-color);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--text-tertiary-color);
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--text-tertiary-color);
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--text-tertiary-color);
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-tertiary-color);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-tertiary-color);
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--text-tertiary-color);
}

/* .dropdown-content li > a {
  color: var(--accent-color) !important;
} */

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 97.5%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 87%; /* 82% */
  }

  .regular-page {
    padding-left: 220px;
  }

  .regular-container {
    width: 82%;
  }

  .container-navbar {
    width: 97% !important;
    max-width: 97%;
  }

  .navbar-fixed {
    left: 250px;
  }

  .hover-lift {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .hover-lift:hover {
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -o-transform: scale(1.03);
    transform: scale(1.03);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.reg-input {
  font-size: 11pt !important;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: "Museo Sans", -apple-system, system-ui, Roboto,
    BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

main {
  flex: 1 0 auto;
}

.m-item {
  margin-left: -5px !important;
}

.operation-card {
  cursor: pointer;
}

.autocomplete-content li .highlight {
  color: var(--text-secondary-color) !important;
}

.dropdown-content li > a,
.dropdown-content li > span {
  color: var(--text-tertiary-color) !important;
  background-color: var(--surface-bg-color) !important;
}

.dropdown-content li > a,
.dropdown-content li > span:hover {
  background-color: var(--bg-color) !important;
}

svg.caret > path:nth-of-type(1) {
  fill: var(--text-secondary-color) !important;
}

/* .dropdown-content-blur {
  background-color: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
} */

.tutorial-m {
  height: 0;
  position: relative;
  padding-bottom: 130%;
}

.tutorial-m iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tutorial-d {
  height: 0;
  position: relative;
  padding-bottom: 100%;
}

.tutorial-d iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.landing-text {
  line-height: 1.6;
  font-size: 130%;
  opacity: 0.6;
  margin-top: 0px;
}

.p-row {
  margin-bottom: -20px !important;
  margin-top: -20px !important;
}

#d-side-bar {
  width: 5.5%;
  top: 64px;
  left: 0px;
}

#d-side-bar li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#dropdown-company-menu li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.autocomplete-content {
  min-width: 80%;
  /* min-height: 100%; */
}

/* .dropdown-content li>a {
  min-width: 100% !important;
} */

/* #side-bar li > a :hover {
  background-color:  rgba(0,0,0,0.5) !important;
} */

.side-bar-no-btn {
  background-color: rgba(0, 0, 0, 0) !important;
}

@media (min-width: 991px) {
  /* main {
    padding-left: 91px;
  } */
  .sidenav li {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .d-flow-card {
    margin-bottom: 23px;
  }

  .d-no-shadow {
    box-shadow: none !important;
  }

  #d-side-bar {
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  }
}

nav {
  background-color: var(--bg-color-transparent) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  line-height: 64px;
}

.blur-card {
  background-color: var(--bg-color-transparent) !important;
  /* opacity: 0.8; */
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}

.nav-wrapper a {
  color: var(--text-secondary-color);
}

.planned {
  background-color: rgba(255, 255, 255, 0.6) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}

.no-background {
  background-image: none !important;
}

.modal {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }

  /* .funds-content { 
    width: 92vw; 
  } */

  .regular-page {
    padding-left: 0px;
    margin-top: 60px !important;
  }

  .mobile-bg {
    background-color: #f5f5f5 !important;
  }

  .modal {
    font-size: 14px;
    line-height: 20px;
  }

  .modal:after {
    border-radius: 20px !important;
  }

  .full-modal {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    top: 0 !important;
  }

  /* .sidenav-overlay {
    opacity: 0 !important;
  } */

  /* #side-bar {
    background-color: rgba(255,255,255,0.8);
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  } */
}

.collapsible .collapsible-body-without-padding {
  padding: 8px 0px 0px 0px !important;
  border-width: 0px;
}

.no-border {
  border-width: 0px !important;
  border: 0 !important;
  box-shadow: none !important;
}

#funds-info-place .collapsible .collapsible-header {
  background-color: rgba(255, 255, 255, 0);
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.collapsible .collapsible-header .no-margin-padding {
  margin-bottom: none !important;
}

.syfer-link {
  text-decoration: underline;
  color: #fb8202;
  cursor: pointer;
}

.g-recaptcha {
  display: table;
  margin: 0 auto;
}

.check-list {
  margin-top: 15px !important;
}

.calendar-cell {
  /* padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-left: 5vw !important; */
  width: 14.28% !important;
  padding: 7px !important;
}

.bold {
  font-weight: 800;
}

.fact-day {
  background-color: rgba(44, 117, 255, 1);
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.plan-day {
  background-color: rgba(153, 102, 255, 1);
  color: #fff;
  font-weight: 400;
  cursor: pointer;
}

.today {
  outline: 2px solid #fb8202;
  outline-offset: -2px;
}

.small-padding {
  padding: 10px 14px 14px 14px !important;
}

.wp {
  padding: 0px !important;
}

.rp {
  padding-left: 20px;
}

.top-rp {
  padding-top: 12px;
}

.d-flex {
  display: flex;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: var(--accent-color);
}
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: var(--text-primary-color);
}

.switch label input[type="checkbox"]:checked + .lever:before,
.switch label input[type="checkbox"]:checked + .lever:after {
  left: 24px;
}

.switch label .lever:before {
  background-color: var(--accent-color);
  opacity: 0.2;
}

.switch label .lever:before,
.switch label .lever:after {
  top: 4px;
  left: 5px;
}

.switch label .lever {
  background-color: var(--text-tertiary-color);
  height: 28px;
  width: 48px;
  border-radius: 25px;
}

.switch label .lever:after {
  background-color: var(--text-primary-color);
}

.iq-watermark {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: var(--bg-color-transparent) !important;
}

.sidenav li > a > i,
.sidenav li > a > [class^="mdi-"],
.sidenav li > a li > a > [class*="mdi-"],
.sidenav li > a > i.material-icons {
  margin: 0px 20px 0px 0px;
}

.accent-menu-item {
  background-color: var(--accent-color);
  color: var(--text-primary-color) !important;
  border-radius: 10px;
}

.accent-menu-item:hover {
  background-color: var(--accent-color) !important;
}

.accent-glow {
  /*  8px - blur, 3px - glow range */
  -webkit-box-shadow: 0px 0px 8px 3px rgba(61, 80, 250, 0.6);
  -moz-box-shadow: 0px 0px 8px 3px rgba(61, 80, 250, 0.6);
  box-shadow: 0px 0px 8px 3px rgba(61, 80, 250, 0.6);
}

.chip {
  background-color: var(--accent-color);
  color: var(--text-primary-color);
}

.pastel-chip {
  background-color: var(--surface-bg-color);
  color: var(--accent-color);
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
  padding: 12px;
  margin: 0px 3px;
}

.ymaps-2-1-79-ground-pane {
  filter: var(--map-filter);
  -ms-filter: var(--map-filter);
  -webkit-filter: var(--map-filter);
  -moz-filter: var(--map-filter);
  -o-filter: var(--map-filter);
}

.image-theme-filter {
  filter: var(--image-theme-filter);
  -ms-filter: var(--image-theme-filter);
  -webkit-filter: var(--image-theme-filter);
  -moz-filter: var(--image-theme-filter);
  -o-filter: var(--image-theme-filter);
}

.hand-cursor:hover {
  cursor: pointer;
}

.sidenav li > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sq-btn {
  /* width: 90px;
  height: 90px;
  text-transform: none; */
  /* padding-top: 10px; */
  padding: 10px 25px;
  cursor: pointer;
  /* padding-bottom: 20px !important; */
}

.circular-image {
  display: block;
  width: 100%;
  height: 100%;
  background: var(--pastel-shadow-color);
  border-radius: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg {
  background-color: var(--bg-color);
}

.animation-appear {
  opacity: 0;  
  animation: appear 1s forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.accent-counter {
  background-color: var(--accent-color);
  padding: 3px 10px;
  border-radius: 15px;
  color: var(--text-primary-color);
}

.fullscreen-intro {
  position: fixed;
  background-color: var(--bg-color);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  animation: fullscreen-intro-fade 1.5s forwards;
  animation-delay: 5s;
  animation-timing-function: ease;
}

@keyframes fullscreen-intro-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px; 
  }
}

.nott-words {
  animation: nott-words-animation 1.5s forwards;
  animation-timing-function: ease;
}

@keyframes nott-words-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ymaps-2-1-79-balloon {
  /* -webkit-box-shadow: var(--pastel-shadow-color) !important;
  -moz-box-shadow: var(--pastel-shadow-color) !important;
  box-shadow: var(--pastel-shadow-color) !important; */
  /* box-shadow: none !important;  */
  border-radius: 15px;
}

.ymaps-2-1-79-balloon__content {
  /* padding: 0 !important; */
  background: transparent !important;
  margin: 25px 12px 0px 0px !important;
  /* background-color: var(--surface-bg-color) !important; */
}

.ymaps-2-1-79-balloon__layout {
  /* background: transparent !important; */
  background-color: var(--bg-color-transparent) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  border: none !important;
  border-radius: 15px;
}

.ymaps-2-1-79-balloon__tail {
  /* background-color: var(--bg-color-transparent) !important; */
  /* -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px); */
  background: none !important;
  z-index: -999 !important;
  border: 0px !important;
  /* box-shadow: none !important; */
}

.ymaps-2-1-79-balloon__tail::after {
  /* background: transparent !important; */
  background-color: var(--bg-color-transparent) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  border: 0px !important;
  /* box-shadow: none !important; */
}

.ymaps-2-1-79-balloon__close {
  margin-right: 15px;
  /* background-color: red !important; */
}

.ymaps-2-1-79-balloon__close-button {
  /* background-color: red !important; */
  filter: var(--image-theme-filter);
  -ms-filter: var(--image-theme-filter);
  -webkit-filter: var(--image-theme-filter);
  -moz-filter: var(--image-theme-filter);
  -o-filter: var(--image-theme-filter);
}

.ymaps-2-1-79-islets_icon-caption {
  background-color: var(--bg-color-transparent) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  color: var(--text-secondary-color);
  /* -webkit-box-shadow: var(--pastel-shadow-color) !important;
  -moz-box-shadow: var(--pastel-shadow-color) !important;
  box-shadow: var(--pastel-shadow-color) !important; */
}

.confirm-container {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-transparent) !important;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  overflow: hidden;
  z-index: 2000; 
  animation: appear 0.2s forwards;
}

.confirm-dialog {
  display: none;
  flex-direction: column;
  position: fixed;
  background-color: var(--surface-bg-color);
  -webkit-box-shadow: var(--pastel-shadow-color);
  -moz-box-shadow: var(--pastel-shadow-color);
  box-shadow: var(--pastel-shadow-color);
  max-width: 430px;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 15px;
  padding: 25px;
  z-index: 2001; /* Higher than the z-index of the background */
  animation: appear 0.2s forwards;
}

.horizontal-scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.horizontal-scrolling-wrapper .card {
  display: inline-block;
  margin-right: 20px;
  overflow: hidden;
  white-space: normal;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: top;
}

.horizontal-scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 992px) {
  .post-image-grid {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: repeat(auto-fit, 200px);
  }

  .carousel.carousel-slider .carousel-item {
    min-height: 200px;
    height: 250px;
  }

  .carousel-img-bg-container {
    height: 250px;
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
  }
}

@media (min-width: 991px) {
  .post-image-grid {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fit, 500px);
  }

  .carousel.carousel-slider .carousel-item {
    min-height: 200px;
    height: 400px;
  }

  .carousel-img-bg-container {
    height: 400px;
    overflow: hidden;
    width: 100%;
    position: absolute;
    left: 0;
  }
}

/* .carousel::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: var(--img-bg);
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  -webkit-filter: blur(25px);
  -moz-filter: blur(25px);
  -o-filter: blur(25px);
  -ms-filter: blur(25px);
  filter: blur(25px);
} */

.carousel-img-bg {
  /* content: ""; */
  position: absolute;
  /* margin-top: 15px; */
  top: 0; 
  left: 0;
  /* right: 0; */
  z-index: -1;

  /* display: block; */
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  overflow:hidden;

  -webkit-filter: blur(50px);
  -moz-filter: blur(50px);
  -o-filter: blur(50px);
  -ms-filter: blur(50px);
  filter: blur(50px);
}

.indicators {
  margin-bottom: 10px !important;
  background-color: var(--bg-color-transparent);
  height: 20px;
  width: 200px;
  border-radius: 15px;
  margin: 0px auto 10px !important;
  -webkit-box-shadow: var(--pastel-shadow-color);
  -moz-box-shadow: var(--pastel-shadow-color);
  box-shadow: var(--pastel-shadow-color);
  /* margin-bottom: 10px !important; */
}

.indicator-item {
  margin-top: 6px !important;
  background-color: var(--text-tertiary-color) !important;
}

.chat-btn {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: var(--btn-bg-color);
  z-index: 99999;
  position: fixed;
  right: 20px;
  bottom: 30px; 
  cursor: pointer;
}

.chat-btn-icon {
  color: var(--text-primary-color) !important;
  margin-left: 13px;
  margin-top: 13px;
  font-size: 35px;
}

/* .carousel {
  background-image: var(--logo-url);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  width: 1200px;
  height: 800px;
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px);
} */

.chat-widget {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* .chat-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
} */

.chat-window {
  width: 340px;
  height: 630px;
  background-color: var(--bg-color);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-header {
  background-color: var(--surface-bg-color);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.chat-header-info {
  background-color: var(--surface-bg-color);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.chat-close-button {
  background: none;
  border: none;
  color: var(--text-secondary-color);
  font-size: 18px;
  cursor: pointer;
}

.chat-messages {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .chat-message {
  padding: 8px 12px;
  border-radius: 15px;
  max-width: 80%;
}

.chat-message.user {
  background-color: #d1e7dd;
  align-self: flex-end;
}

.chat-message.support {
  background-color: #f8d7da;
  align-self: flex-start;
} */

/* .chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f1f1f1;
} */

.chat-input {
  display: flex;
  margin-top: 15px;
  background-color: var(--surface-bg-color);
}

/* .chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
} */

/* .chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
} */
